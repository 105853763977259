function App() {
  return (
    <div>
      <h3>Page held for client by <a href="https://www.yourapp.ie">YourApp.ie</a>.</h3>
      <p>
        For free initial consultation, ring (087)6202101.
      </p>
    </div>
  );
}

export default App;